<script lang="ts">
	import Logo from '$lib/components/layout/Logo.svelte';
	import { theme } from '$lib/stores/theme';
	import ThemeController from '$lib/components/layout/ThemeController.svelte';
	import Icon from '@iconify/svelte';
	import { cookies } from '$lib/stores/cookies';
	import { PUBLIC_DASHBOARD_BASE_URL } from '$env/static/public';

	const socials: {
		href: string;
		title: string;
		icon: string;
	}[] = [
		{
			href: 'https://x.com/easypagego',
			title: 'Twitter',
			icon: 'fa6-brands:x-twitter'
		},
		{
			href: 'https://www.linkedin.com/in/easypagego/',
			title: 'LinkedIn',
			icon: 'uil:linkedin'
		},
		{
			href: 'https://dev.to/easypagego',
			title: 'Dev.to',
			icon: 'fa6-brands:dev'
		},
		{
			href: 'https://join.slack.com/t/easypagego/shared_invite/zt-2py2akggi-5GtKxKVh3kJTaWBzEVLm5A',
			title: 'Slack',
			icon: 'gg:slack'
		}
	] as const;
</script>

<footer class="footer footer-center gap-y-6 text-base-content rounded py-20 bg-base-200">
	<Logo />
	<nav class="flex items-center flex-wrap gap-6">
		<a href="/eula/" class="link link-hover">EULA</a>
		<a href="/privacy-policy/" class="link link-hover">Privacy Policy</a>
		<a href="/cookie-policy/" class="link link-hover">Cookie Policy</a>
		<a href="/refund-policy/" class="link link-hover">Refund Policy</a>
		<a href="/changelog/" target="_blank" class="link link-hover"> Changelog </a>
		<a href="/free-tools/" class="link link-hover">Free Tools</a>
		<a href="/blog/" target="_blank" class="link link-hover"> Blog </a>
		<a href="{PUBLIC_DASHBOARD_BASE_URL}/demo/landing/" target="_blank" class="link link-hover">
			Try for Free
		</a>

		<a href="mailto:support@easypagego.com" class="link link-hover"> Support </a>
	</nav>
	<div class="flex items-center flex-wrap justify-around gap-4">
		<div class="flex items-center justify-center flex-wrap gap-4">
			<button type="button" class="btn btn-circle btn-ghost" on:click={theme.toggle}>
				<span class="sr-only">Toggle Appearance</span>
				<ThemeController />
			</button>
			<button type="button" class="btn btn-circle btn-ghost" on:click={cookies.addCheckboxes}>
				<span class="sr-only">Manage Cookies</span>
				<Icon icon="ic:round-cookie" width="20" height="20" />
			</button>
		</div>
		<nav class="flex items-center justify-center flex-wrap gap-4">
			{#each socials as { href, title, icon }}
				<a {href} target="_blank" class="btn btn-circle btn-ghost">
					<span class="sr-only">{title}</span>
					<Icon {icon} width="20" height="20" />
				</a>
			{/each}
		</nav>
	</div>
	<!-- <a href="/free-tools/" target="_blank" class="link link-hover"> Free tools </a> -->

	<aside class="w-full flex items-center gap-8">
		<span class="flex-1 text-right">
			Copyright © {new Date().getFullYear()}
		</span>
		<a
			href="https://l13o.com/"
			target="_blank"
			rel="noopener noreferrer"
			class="flex-1 text-left inline-block link link-hover"
		>
			L13o s.r.l.
		</a>
	</aside>
</footer>
