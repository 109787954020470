<script lang="ts" context="module">
	import { globsToMap } from '$lib/utils/images';

	const image = import.meta.glob('$assets/images/shared/logo.png', {
		eager: true,
		query: {
			enhanced: true,
			quality: 100,
			w: '60',
			h: '60',
			lossless: false
		}
	});
	const map = globsToMap(image);
</script>

<div>
	<a href="/" aria-label="Easy Page GO" class="btn btn-ghost gap-0.5 flex-shrink-0">
		<div class="flex flex-col">
			<span>Easy</span>
			<span>Page</span>
		</div>
		<enhanced:img
			src={map.get('logo').default}
			alt="EasyPageGo"
			class="w-10 h-10 bg-cover rounded-box"
			loading="eager"
			decoding="async"
			aria-label="GO"
		/>
	</a>
</div>
