<script lang="ts">
	import { cookies } from '$lib/stores/cookies';
</script>

<div class="card card-bordered card-compact bg-base-200/85 backdrop-blur-sm shadow-xl max-w-screen-sm">
	<div class="card-body gap-0 prose-sm sm:prose-lg">
		<strong class="!mb-5 card-title">Cookie consent</strong>
		<div>
			<div class="flex items-center gap-2">
				<input type="checkbox" class="checkbox checkbox-secondary" disabled checked={$cookies.necessary} />
				<strong>Strictly necessary cookies</strong>
			</div>
			<p class="!mt-1.5">
				These are cookies that are required for the operation of our Site and under our terms with
				you. They include, for example, cookies that enable you to log into secure areas of our Site
				or (on other sites) use a shopping cart or make use of e-billing services.
			</p>
		</div>

		<div>
			<div class="flex items-center gap-2">
				<input
					type="checkbox"
					class="checkbox checkbox-secondary"
					checked={$cookies.analytics}
					on:change={cookies.toggleAnalytics}
				/>
				<strong>
					Analytical Cookies
				</strong>
			</div>
			<p class="!mt-1.5">
				These allow us to recognize and count the number of visitors and to see how visitors move
				around our site when they are using it. This helps us improve the way our Website works, for
				example, by ensuring that users are finding what they are looking for easily.
			</p>
		</div>

		<div class="card-actions justify-end">
			<button type="button" on:click={cookies.cancel} class="btn btn-ghost btn-sm"> Cancel </button>
			<button type="button" on:click={cookies.save} class="btn btn-secondary btn-outline btn-sm">
				Save
			</button>
		</div>
	</div>
</div>
