<script lang="ts">
	import '$assets/css/app.css';
	import DebugScreens from '$lib/components/dev/DebugScreens.svelte';
	import { theme } from '$lib/stores/theme';
	import Toasts from '$lib/components/Toasts.svelte';
	import { cookies } from '$lib/stores/cookies';
	import { dev } from '$app/environment';
	import Footer from '$lib/components/layout/Footer.svelte';
	import Header from '$lib/components/layout/Header.svelte';
	import ViewTransition from '$lib/components/ViewTransition.svelte';

	theme.init();
	setTimeout(() => cookies.init(), 0);
</script>

<svelte:head>
	<!-- Google tag (gtag.js) -->
	{#if !dev}
		<script async src="https://www.googletagmanager.com/gtag/js?id=G-7CKCSZW8CY"></script>
		<script>
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());
			gtag('config', 'G-7CKCSZW8CY');
		</script>
	{:else}
		<script>
			console.log('[dev] google tag not enabled');
		</script>
	{/if}
	<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
	<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
	<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
	<link rel="manifest" href="/site.webmanifest" />
</svelte:head>

<div class="w-screen min-h-screen grid grid-cols-1 grid-rows-[min_content-auto_min-content]">
	<Header class="z-50" />
	<div class="pt-16">
		<slot />
	</div>
	<Footer />
</div>

<Toasts />
<ViewTransition />
<DebugScreens />
