<script lang="ts">
	import { cookies } from '$lib/stores/cookies';
</script>

<div class="card card-bordered card-compact bg-base-200/85 backdrop-blur-sm shadow-xl max-w-screen-sm">
	<div class="card-body gap-0 prose-sm sm:prose-lg">
		<p>
			We and selected third parties use cookies or similar technologies for technical purposes and,
			with your consent, for other purposes. Denying consent may make related features unavailable.
			You can freely give, deny, or withdraw your consent at any time. Use the “Accept” button to
			consent.
		</p>
		<div class="card-actions justify-end items-center">
			<button type="button" class="link link-hover link-secondary" on:click={cookies.addCheckboxes}>
				Learn more and customize
			</button>
			<button type="button" class="btn btn-secondary btn-outline btn-sm" on:click={cookies.accept}>
				Accept
			</button>
		</div>
	</div>
</div>
