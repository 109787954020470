<script lang="ts" context="module">
	declare global {
		interface ViewTransitionAPI {
			startViewTransition: (promiseGenerator: () => Promise<void>) => void;
		}

		interface Document extends ViewTransitionAPI {}
	}
</script>

<script lang="ts">
	import { onNavigate } from '$app/navigation';
	import '$assets/css/view-transition.css';

	onNavigate((navigation) => {
		if (!document.startViewTransition) return;

		return new Promise((resolve) => {
			document.startViewTransition(async () => {
				resolve();
				await navigation.complete;
			});
		});
	});
</script>
